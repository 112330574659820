import { ChangeEvent, Fragment, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ChartDataset } from 'chart.js';
import {
  differenceInCalendarMonths,
  endOfMonth,
  format,
  isSunday,
  subYears,
} from 'date-fns';
import { graphql, Link, navigate, PageProps, useStaticQuery } from 'gatsby';
import queryString from 'query-string';

import PageTemplate from 'layouts/page-templates/hourly-workforce-index';

import HWIAPI from 'utils/api/hourly-workforce-index';
import { windowCheck } from 'utils/helpers';
import trackEvent from 'utils/mercury/trackOnInteraction';

import {
  HwiRegionsWithHistoryHistory,
  HwiRegionsWithHistoryNode,
} from 'types/hourly-workforce-index';

import HWICardsRow from 'components/content-blocks/hourly-workforce-index/cards-row';
import HWILargeCompareCard from 'components/content-blocks/hourly-workforce-index/large-compare-card';
import HWILineChart from 'components/content-blocks/hourly-workforce-index/line-chart';
import HWIGraphPlaceholder from 'components/content-blocks/hourly-workforce-index/placeholders/graph';
import HWILargeCardPlaceholder from 'components/content-blocks/hourly-workforce-index/placeholders/large-card';
import HWISmallCardPlaceholder from 'components/content-blocks/hourly-workforce-index/placeholders/small-card';
import HWIStateCompareCard from 'components/content-blocks/hourly-workforce-index/state-compare-card';
import HWIStateSelector from 'components/content-blocks/hourly-workforce-index/state-selector';
import formatDateRange from 'components/content-blocks/hourly-workforce-index/util/format-date-range';
import getDateOffset from 'components/content-blocks/hourly-workforce-index/util/get-date-offset';

import { hwiPageUrl } from '.';

type PageContext = {
  acf: {
    hwi_national: {
      national_label: string;
    };
    states: {
      large_card_label: string;
      small_card_bottom_label: string;
      small_card_top_label: string;
      state: Array<{
        initial: string;
        name: string;
      }>;
    };
  };
};

type MemoizedPageData = [
  HwiRegionsWithHistoryNode | null | undefined,
  HwiRegionsWithHistoryHistory | null | undefined,
  string | null | undefined,
  string | null | undefined
];

function mapRegionDataToGraphPoints(
  data: HwiRegionsWithHistoryNode
): { x: number; y: number }[] {
  return data.history
    .filter(point => {
      const days = new Date(point.date);
      if (isSunday(days)) {
        return days.getDate();
      }
    })
    .filter(
      point =>
        differenceInCalendarMonths(new Date(), new Date(point.date)) <= 12
    )
    .map(point => ({
      x: new Date(point.date).getTime(),
      y: point.percentChange,
    }));
}

const HourlyWorkforceIndexStatePage = (props:PageProps) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "hourly-workforce-index" } }) {
        edges {
          node {
            wordpress_id
            acf {
              hwi_national {
                national_label
              }
              states {
                state {
                  initial
                  name
                }
                large_card_label
                small_card_top_label
                small_card_bottom_label
              }
            }
          }
        }
      }
    }
  `);
  const pageContext: PageContext = data.allWordpressPage.edges[0].node;

  const queryParams = queryString.parse(props.location.search);

  const regionalQuery = useQuery(['hwi-regional', queryParams.state], () => {
    if (queryParams.state && queryParams.state !== '') {
      return HWIAPI.GetRegionalData(queryParams.state as string);
    }
    return null;
  });

  const handleStateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const action = 'click';
    let target = value;
    let destination = `${props.location.pathname}?state=${value}`;

    if (value === '') {
      target = 'National Index';
      destination = props.location.pathname;
      trackEvent(action, module, target, destination);
      navigate(hwiPageUrl);
      return;
    }
    trackEvent(action, module, target, destination);
    navigate(`${props.location.pathname}?state=${value}`);
  };

  const [
    stateData,
    latestDataPoint,
    endLastMonth,
    startLastYear,
  ]: MemoizedPageData = useMemo(() => {
    if (!regionalQuery.data || !queryParams.state)
      return [null, null, null, null];

    const latest = [...regionalQuery.data.history].sort((dayA, dayB) =>
      dayA.date > dayB.date ? -1 : 1
    )[0];

    const endLastMonth = format(
      endOfMonth(getDateOffset(regionalQuery.data.lastMonth.date)),
      'Y-MM-dd'
    );
    const startLastYear = format(
      subYears(getDateOffset(regionalQuery.data.lastYear.date), -1),
      'Y-MM-dd'
    );

    return [regionalQuery.data, latest, endLastMonth, startLastYear];
  }, [queryParams, regionalQuery]);

  const graphData: ChartDataset[] | undefined = useMemo(() => {
    if (!regionalQuery.data) return undefined;

    return [
      {
        backgroundColor: '#51a33d',
        borderColor: '#51a33d',
        cubicInterpolationMode: 'monotone',
        data: mapRegionDataToGraphPoints(regionalQuery.data),
        fill: false,
        indexAxis: 'x',
        label: 'Hourly Workforce Index',
      },
    ];
  }, [regionalQuery.data]);

  // navigate away if there is no state query param, it is empty, or the provided state param isn't in the data
  if (
    windowCheck &&
    (!queryParams.state || queryParams.state === '' || stateData === undefined)
  ) {
    navigate(hwiPageUrl);
  }

  return (
    <PageTemplate>
      <section className="hwi--view-wrapper" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <div className="container container-large is-max-desktop">
          <div className="hwi--data-container">
            <div className="hwi--view-indicator">
              <HWIStateSelector
                allStatesLabel={ pageContext.acf.hwi_national.national_label }
                onChange={ handleStateChange }
                options={ pageContext.acf.states.state.map(state => ({
                  short: state.initial,
                  name: state.name,
                })) }
                value={ queryParams.state as string }
              />
            </div>
            <div className="hwi--view-by-links">
              <a
                className="button is-filled-green is-rounded"
                href={ stateData?.hwiLink ?? '' }
                target="_blank"
                rel="noreferrer"
              >
                <span className="is-hidden-mobile">View Interactive&nbsp;</span>
                Map
              </a>
              <Link
                className="button is-green is-rounded"
                to={ `${hwiPageUrl}/industry` }
              >
                <span className="is-hidden-mobile">View By&nbsp;</span>Industry
              </Link>
            </div>
            <div className="hwi--main-chart">
              {graphData ? (
                <HWILineChart dataSets={ graphData } />
              ) : (
                <HWIGraphPlaceholder />
              )}
            </div>
            <HWICardsRow
              first={
                <div className="hwi--compare-previous">
                  {stateData ? (
                    <Fragment>
                      <HWIStateCompareCard
                        date={
                          endLastMonth
                            ? formatDateRange(
                              stateData.lastMonth.date,
                              endLastMonth,
                              true
                            )
                            : ''
                        }
                        hwiChangeValue={ stateData.lastMonth.change }
                        linkUrl={ stateData.lastMonth.hwiLink }
                        mapImageUrl={ HWIAPI.MakeRegionalMapImageUrl(
                          stateData,
                          'lastMonth'
                        ) }
                        stateName={ stateData.stateName }
                        title={ pageContext.acf.states.small_card_top_label }
                      />
                      <HWIStateCompareCard
                        date={
                          startLastYear
                            ? formatDateRange(
                              stateData.lastYear.date,
                              startLastYear,
                              false
                            )
                            : ''
                        }
                        hwiChangeValue={ stateData.lastYear.change }
                        linkUrl={ stateData.lastYear.hwiLink }
                        mapImageUrl={ HWIAPI.MakeRegionalMapImageUrl(
                          stateData,
                          'lastYear'
                        ) }
                        stateName={ stateData.stateName }
                        title={ pageContext.acf.states.small_card_bottom_label }
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <HWISmallCardPlaceholder />
                      <HWISmallCardPlaceholder />
                    </Fragment>
                  )}
                </div>
              }
              second={
                <div className="hwi--compare-present">
                  {stateData && latestDataPoint ? (
                    <HWILargeCompareCard
                      backgroundImage={ {
                        alt_text: `Map of ${stateData.stateName}`,
                        source_url: HWIAPI.MakeRegionalMapImageUrl(
                          stateData,
                          'thisMonth'
                        ),
                      } }
                      changeValue={ stateData.thisMonth.change }
                      date={ formatDateRange(
                        stateData.thisMonth.date,
                        latestDataPoint.date,
                        false
                      ) }
                      linkUrl={ stateData.thisMonth.hwiLink }
                      title={ pageContext.acf.states.large_card_label }
                    />
                  ) : (
                    <HWILargeCardPlaceholder />
                  )}
                </div>
              }
            />
          </div>
        </div>
      </section>
    </PageTemplate>
  );
};

export default HourlyWorkforceIndexStatePage;
